.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.content {
  width: 75%;
  height: 75%;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.ipip-form {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 1rem;
}

.btn-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-direction: column;
  max-width: 25%;
}

.btn-group .prev-btn,
.btn-group .next-btn {
  background-color: #1976d2;
  color: white;
}

.btn-group .prev-btn:hover,
.btn-group .next-btn:hover {
  background-color: #115293;
}

.btn-group .Mui-disabled {
  background-color: #ccc;
  color: #fff;
}
