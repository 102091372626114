.login-buttons {
  display: flex;
  align-items: center; /* Vertically align the buttons */
  flex-direction: column;
  justify-content: space-between;
}

.login-buttons > * {
  margin-bottom: 12px; /* Add space between the buttons */
}

.login-buttons > *:last-child {
  margin-bottom: 0; /* Remove margin after the last item */
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}
